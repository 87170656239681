import { React, useState } from "react";
import { useParams } from "react-router-dom";
import GeneralInfo from "./ConstructionSite/GeneralInfo";
import { Row, Col } from "antd";
import Tolboxes from "./ConstructionSite/Tolboxes";
import Workers from "./ConstructionSite/Workers";
import "./ConstructionSites.scss";
import he from 'he';

const ConstructionSitesDetails = () => {
  const siteID = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [adminTitle, setAdminTitle] = useState("");
  const childData = (data, adminTitle) => {
    setIsLoaded(data);
    setAdminTitle(adminTitle);
  };
  return (
    <div className="construction-sites-details-wrapper">
      <Row>
        <Col span={16} offset={2}>
          {isLoaded && (
            <h1>
              {window.location.href
                .split("/")
                .includes("archive-construction-sites")
                ? "Archive Construction Site "
                : "Construction Site "}
              {he.decode(adminTitle)}
            </h1>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={2} className="general-info-ipad">
          <GeneralInfo siteID={siteID.id} parentFunction={childData} />
          <Workers siteID={siteID.id} adminTitle={adminTitle} />
        </Col>
        <Col span={9} className="general-info-ipad">
          <Tolboxes siteID={siteID.id} />
        </Col>
      </Row>
    </div>
  );
};

export default ConstructionSitesDetails;
