import { React, useState, useEffect } from "react";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Table, Spin, Button, message, Modal } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "src/context/auth-context";
// import jQuery from "jquery";
import axios from "axios";
import "../WorkerDetailsPage.scss";
import he from 'he';

const WorkersToolbox = (props) => {
  const auth = useAuth();
  const [toolboxes, settoolboxes] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [toolboxTitle, setToolboxTitle] = useState("")
  const workerLang = props.data.language;
  const url = `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/get_toolbox_per_worker?name=${props.siteID}&construction_site=${props.construction_site_id}`;

  // console.log(url, "url");
  const { tableData, columns } = getTableData();
  function handleClickNA(toolbox) {
    axios({
      url: `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/send_toolbox_per_worker?worker=${props.siteID}&toolbox=${toolbox}&construction_site=${props.construction_site_id}&not_applicable=true`,
      method: "post",
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
    })
      .then((NAresponse) => {
        if (NAresponse.status === 201) {
          message.success("Updated!");
        }
      })
      .catch((NAerror) => {
        message.error("Error!");
        console.log("NAerror", NAerror);
      });
    // console.log("URL",url)
  }

  const handleShow = (toolbox_title) => {
    setToolboxTitle(toolbox_title);
    const url = `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/get_toolbox?name=${toolbox_title}&lang=${workerLang}&construction_site=${props.construction_site_id}`;
    // console.log("URL", url);
    axios({
      url: url,
      method: "get",
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
    })
      .then((response) => {
        // console.log("Resposne", response);
        const base64Response = response.data;
        if (base64Response === "") {
          message.error("File Not Found");
        } else {
          // console.log("b64", b64);
          window.showPdf(base64Response);
        }
      })
      .then(() => setOpen(true));
  };
  const handleCancel = () => {
    setOpen(false);
    // window.clearDoc();
  };
  const handleSavePdf = () => {
    const b64 = window.savePDF();
    const b64Split = b64.split("base64,");
    setOpen(false);
    axios({
      url: `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/send_toolbox_per_worker?worker=${props.siteID}&toolbox=${toolboxTitle}&construction_site=${props.construction_site_id}`,
      method: "post",
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
      data: {
        file: b64Split[1],
      },
    })
      .then((response) => {
        // console.log("response", response);
        if (response.status === 201) {
          message.success("Signed Sucessfully!");
          // var existingContent = document.querySelector("#pdf-container");
          // if (existingContent) {
          //   existingContent.innerHTML = "";
          // }
        } else {
          message.error("!Oopss Something Went Wrong");
        }
      })
      .catch((err) => {
        message.error("!Oopss Something Went Wrong");
        console.log("Error", err);
      });
  };
  const handleViewPdf = (toolbox_title) => {
    axios({
      url: `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/view_toolbox_per_worker?worker=${props.siteID}&toolbox=${toolbox_title}&signed=true`,
      method: "get",
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
    })
      .then((response) => {
        // console.log("Resposne", response);
        const base64Response = response.data;
        if (base64Response === "") {
          message.error("File Not Found");
        } else {
          // console.log("b64", b64);
          window.viewPdf(base64Response);
        }
      })
      .then(() => setOpenView(true))
      .catch((err) => console.log("Error", err));
  };
  const handleBrushChange = (e) => {
    window.pdf.setBrushSize(e.target.value);
  };

  useEffect(() => {
    axios({
      url: url,
      method: "get",
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
    })
      .then((toolboxesResponse) => {
        settoolboxes(toolboxesResponse.data);
        // console.log("toolboxes", toolboxesResponse.data);
        setisLoading(true);
      })
      .catch((toolboxesError) => {
        console.log("toolboxesError", toolboxesError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, toolboxes]);
  return !isLoading ? (
    <Spin size="large" />
  ) : (
    <div className="worker-toolbox">
      <Modal
        title="PDF Signature"
        centered
        visible={open}
        mask={true}
        onOk={() => handleSavePdf()}
        onCancel={() => handleCancel()}
        width="fit-content"
        okText="Save"
        destroyOnClose="true"
        style={{
          top: 60,
        }}
      >
        <div className="toolbar">
          <div className="tool">
            <label>Brush size:&nbsp;</label>
            <input
              type="number"
              className="form-control text-right"
              defaultValue="1"
              id="brush-size"
              max="50"
              onChange={(e) => handleBrushChange(e)}
            />
          </div>

          <div className="tool">
            <button className="tool-button active">
              <i
                className="fa fa-hand-paper-o"
                title="Free Hand"
                onClick={(event) => window.enableSelector(event)}
              ></i>
            </button>
          </div>
          <div className="tool">
            <button className="tool-button">
              <i
                className="fa fa-pencil"
                title="Pencil"
                onClick={(event) => window.enablePencil(event)}
              ></i>
            </button>
          </div>

          <div className="tool">
            <button
              className="btn btn-danger btn-sm"
              onClick={(event) => window.deleteSelectedObject(event)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
          <div className="tool">
            <button
              className="btn btn-danger btn-sm"
              onClick={() => window.clearPage()}
            >
              Clear Page
            </button>
          </div>
          {/* <div className="tool">
            <button
              className="btn btn-info btn-sm"
              onClick={() => window.showPdfData()}
            >
              Show
            </button>
          </div> */}
          {/* <div className="tool">
            <button
              className="btn btn-light btn-sm"
              onClick={() => window.savePDF()}
            >
              <i className="fa fa-save"></i> Save
            </button>
          </div> */}
        </div>
        <div id="pdf-container"></div>
      </Modal>
      <Modal
        title="View PDF"
        centered
        visible={openView}
        mask={true}
        onOk={() => setOpenView(false)}
        onCancel={() => setOpenView(false)}
        width="fit-content"
        okText="Close"
        destroyOnClose="true"
        // closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div id="pdf-container-view"></div>
      </Modal>
      <h3>Toolboxes</h3>
      <Link
        className="add-toolbox-per-worker"
        to={`/add-toolbox-per-worker/${props.siteID}`}
      >
        <Button disabled type="primary">
          Add Toolboxes
        </Button>
      </Link>
      <Table dataSource={tableData} columns={columns} />
    </div>
  );

  function getTableData() {
    const tableData = toolboxes.map((item, index) => {
      const title = item.toolbox;
      const signed = item.status;
      const sign = () => {
        if (signed === "Not signed") {
          return <WarningOutlined style={{ color: "red" }} />;
        } else if (signed === "Not applicable") {
          return <p style={{ color: "rgb(254, 198, 0)", margin: 0 }}>N/A</p>;
        } else {
          return <CheckCircleOutlined style={{ color: "green" }} />;
        }
      };
      const action = () => {
        if (signed === "Not signed") {
          return (
            <>
              <div className="button-wrapper">
              <Button
                type="primary"
                size="small"
                style={{
                  background: "rgb(57, 200, 112)",
                  borderColor: "rgb(57, 200, 112)",
                  marginRight: 20,
                  borderRadius: 5,
                }}
                onClick={() => handleShow(item.toolbox)}
              >
                Sign Now
              </Button>
              <Button
                type="primary"
                size="small"
                style={{
                  background: "rgb(254, 198, 0)",
                  borderColor: "rgb(254, 198, 0)",
                  borderRadius: 5,
                }}
                onClick={() => {
                  handleClickNA(item.toolbox);
                }}
              >
                N/A
              </Button>
              </div>
            </>
          );
        } else if (signed === "Not applicable") {
          return (
            <>
              <div className="button-wrapper">
              <Button
                type="primary"
                size="small"
                style={{
                  background: "rgb(57, 200, 112)",
                  borderColor: "rgb(57, 200, 112)",
                  borderRadius: 5,
                }}
                onClick={() => handleShow(item.toolbox)}
              >
                Sign Now
              </Button>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="button-wrapper">
              <Button
                type="primary"
                size="small"
                style={{ marginRight: 20, borderRadius: 5 }}
                onClick={() => handleViewPdf(item.toolbox)}
              >
                View
              </Button>
              </div>
              {/* <Button type="primary" size="small" style={{ borderRadius: 5 }}>
                Edit
              </Button> */}
            </>
          );
        }
      };
      return {
        key: index,
        admin_title: he.decode(props.adminTitle),
        name: title,
        con_site: props.construction_site_id,
        signed: sign(),
        action_view: action(),
      };
    });

    const columns = [
      {
        title: "Name toolbox",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Admin title",
        dataIndex: "admin_title",
        key: "admin_title",
      },
      {
        title: "Construction site",
        dataIndex: "con_site",
        key: "con_site",
      },
      {
        title: "Signed",
        dataIndex: "signed",
        key: "signed",
      },
      {
        title: "Actions",
        dataIndex: "action_view",
        key: "action_view",
      },
    ];
    return { tableData, columns };
  }
};

export default WorkersToolbox;
